export const utils = {
    convertToThaiDate(date: string | undefined) {

        if (!date) throw new Error("Date is not Undefined");

        const d = new Date(date);
        // const month = ["ມັງກອນ", "ກຸມພາ", "ມີນາ", "ເມສາ", "ພຶດສະພາ", "ມິຖຸນາ",
        //     "ກໍລະກົດ", "ສິງຫາ", "ກັນຍາ", "ຕຸລາ", "ພະຈິກ", "ທັນວາ"];
        const month = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
        return d.getDate() + ' ' + month[d.getMonth()] + ' ' + (d.getFullYear() + 543)
    }
}